@import "../styles/variable";

.table-of-profiles {
  color: #0d47a1;
  font-weight: bold;
  font-size: 23px;
  padding: 0 16px 0 32px;
  margin-top: 16px;
}

.table-of-profiles.fixed {
  position: fixed;
  top: var(--kt-app-header-height);
  height: 60vh;
  overflow-y: scroll;
}

.item-of-profile {
  padding-left: 16px;
  line-height: 25px;
  font-weight: 700;
  font-size: 16px;
  color: #595959;
  position: relative;
}

.item-of-profile.active {
  color: #519DD9;
}

.item-of-profile.active::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 10px;
  width: 2px;
  height: 18px;
  background-color: #519DD9;
}

.item-of-table:active {
  border-left: 2px solid #519dd9;
}

.title-of-profiles {
  color: #0d47a1;
  font-size: 23px;
  line-height: 32px;
  font-weight: 700;
}

.block-content {
  margin-top: 14px;
  // margin-left: 14px;
}

.content {
  width: 100%;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px 30px;
  margin-top: 10px;
  margin-bottom: 20px;

  &-custom {
    margin-top: 48px;
  }
}

.content-two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.item-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item-content>* {
  align-self: flex-start;
}

// .item-content span {
//   margin-bottom: 6px;
// }

.table-block {
  margin-bottom: 25px;
  margin-top: 25px;
}

.text-add-field {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  color: #2b6dba;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.open-field {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.rich-list {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.center {
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.checkbox-content {
  border: 1px solid #519dd9;
}

.input {
  max-width: 260px;
}

.text-wrapper {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 155%;
}

.main__info {
  height: 180px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    display: inline-block;
    padding: 0 !important;
  }
}

.main__info-view {
  display: grid;
  grid-template-columns: 5fr 7fr;
}

.main__info--left {
  margin: 16px 0px;
  display: flex;
  // gap: 16px;
  justify-content: space-around;
}

.main__info--image {
  width: 120px;
  height: 120px;
  border-radius: 100rem;
}

.main__info--employee {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 12px;

  .name {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  .code {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
  }
}

.main__info--right {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px 32px;
  margin: 27px 10px;
}

.main__info--block {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-self: start;
  gap: 5px;
}

.main__info--title {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 155%;
}

.main__info--content {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
}

.profile-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout {
  display: grid;
  //  grid-template-columns: 20% 80%;
  width: 100%;
  gap: 20px;
}

.layout-half {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 20px;
}

.relative {
  position: relative;
}

.fixed-button {
  position: fixed;
  right: 0;
  z-index: 10;
  width: fit-content;
}

.fixed-button-profile {
  position: absolute;
  right: -20px;
  z-index: 10;
  width: fit-content;
}

.custom-table {
  border: 1px solid #F4F4F4;
}

.custom-table thead th,
.custom-table tbody td {
  border: 1px solid #F4F4F4;
}

.header-action {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 10px;
  width: 100%;
}

#custom-switch {
  cursor: pointer;
}